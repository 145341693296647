import * as React from "react";
import IntlTelInput from "react-intl-tel-input";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import classnames from "classnames";
// hooks
import usePhoneNumberUtils from "../hooks/usePhoneNumberUtils";

// styles
import "react-intl-tel-input/dist/main.css";
import "../css/phoneInput.css";

const PhoneInput = ({
  id = "",
  name = "",
  placeholder = "",
  className = "",
  value = "",
  required = true,
  invalidMessagePath = "errors.requiredField",
  onChange = () => {},
  forceValidation = false,
  country,
  setCountryCode: setCountryCodeProps,
  countryCode: countryCodeProps
}) => {
  const { t } = useTranslation();
  const phoneInputRef = React.useRef(null);
  const { validator: phoneNumberValidator, placeholder: phoneNumberExample, formatNumber } = usePhoneNumberUtils({ country: countryCodeProps, t })
  const [touched, setTouched] = React.useState(false);
  const isEmpty = required && !value && (touched || forceValidation);
  const isInvalid = phoneNumberValidator(value) && (touched || forceValidation);
  const onPhoneNumberChange = React.useCallback(
    (
      isValid,
      value,
      selectedCountryData,
      fullNumber,
      extension,
    ) => {
      const number = formatNumber(value,  1);

      onChange({ target: { value: number } });
    },
    [onChange, formatNumber, countryCodeProps]
  );
  const onSelectFlag = React.useCallback(
    (
      currentNumber,
      selectedCountryData,
      fullNumber,
      isValid,
    ) => {
      // const number = formatNumber(currentNumber,  PhoneNumberFormat.INTERNATIONAL);

      if (selectedCountryData?.iso2 && selectedCountryData?.iso2?.toUpperCase() !== countryCodeProps?.toUpperCase()) {
        setCountryCodeProps(selectedCountryData?.iso2.toUpperCase());
      }
    },
    [onChange, formatNumber, setCountryCodeProps, countryCodeProps]
  );
  const handleBlur = React.useCallback(() => setTouched(true), []);
  const errorMsgPath = React.useMemo(() => phoneNumberValidator(value), [phoneNumberValidator, value]);

  return (
    <div
      className={`sm:w-1/2 px-3 mb-3 ${className} ${isInvalid || isEmpty ? "error" : ""
        }`}
    >
      <div
        className={classnames("selectedFlag", "selected-flag")}
        onClick={phoneInputRef.current?.clickSelectedFlag}
        onKeyDown={phoneInputRef.current?.handleSelectedFlagKeydown}
        title={phoneInputRef.current?.titleTip}
      />
      <IntlTelInput
        value={formatNumber(value || "",  2)}
        defaultCountry={country?.toLowerCase()}
        containerClassName={classnames("intl-tel-input", "full-width")}
        inputClassName={classnames(
          "form-control phoneInput full-width rounded px-3 py-1 input focus:outline-none",
          {
            "error": isInvalid || isEmpty
          }
        )}
        onPhoneNumberChange={onPhoneNumberChange}
        onSelectFlag={onSelectFlag}
        onPhoneNumberBlur={handleBlur}
        placeholder={phoneNumberExample}
        telInputProps={{
          autoComplete: "on",
          id: "phone"
        }}
        ref={phoneInputRef}
        fieldId="phone"
        autoComplete="on"
        nationalMode
        separateDialCode
      />
      {isEmpty ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {t(invalidMessagePath)}
        </span>
      ) : isInvalid ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {t(errorMsgPath)}
        </span>
      ) : null}
    </div>
  );
};

export default PhoneInput;
